$light-blue: #80a5fb;
$dark-blue: #4c60ba;
$primary-red: #ff0000;
$primary-black: #000000;
$primary-gray: #b2b2b2;
$secondary-gray: #acacac;
$primary-white: #ffffff;
$border-gray: #707070;
$main-gray: #e8e8e8;
$deep-gray: rgba(0, 0, 0, 0.214);
$light-gray: rgba(0, 0, 0, 0.165);

$primary-green: #1fc900;
$primary-blue: #2699fb;
$transparent-blue: #2698fb2f;



@mixin d_flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-font($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
@mixin fluid-width($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      width: $min-font-size;
      @media screen and (min-width: $min-vw) {
        width: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        width: $max-font-size;
      }
    }
  }
}

@mixin fluid-height($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      height: $min-font-size;
      @media screen and (min-width: $min-vw) {
        height: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        height: $max-font-size;
      }
    }
  }
}

@mixin fluid-radius(
  $min-vw,
  $max-vw,
  $min-top,
  $max-top,
  $min-right,
  $max-right,
  $min-bottom,
  $max-bottom,
  $min-left,
  $max-left
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);

  $u3: unit($min-top);
  $u4: unit($max-top);

  $u5: unit($min-right);
  $u6: unit($max-right);

  $u7: unit($min-bottom);
  $u8: unit($max-bottom);

  $u9: unit($min-left);
  $u10: unit($max-left);

  @if $u1 ==
    $u2 and
    $u1 ==
    $u3 and
    $u1 ==
    $u4 and
    $u1 ==
    $u5 and
    $u1 ==
    $u6 and
    $u1 ==
    $u7 and
    $u1 ==
    $u8 and
    $u1 ==
    $u9 and
    $u1 ==
    $u10
  {
    & {
      border-radius: $min-top $min-right $min-bottom $min-left;
      @media screen and (min-width: $min-vw) {
        border-radius: calc(
            #{$min-top} + #{strip-unit($max-top - $min-top)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-right} + #{strip-unit($max-right - $min-right)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-left} + #{strip-unit($max-left - $min-left)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        border-radius: $max-top $max-right $max-bottom $max-left;
      }
    }
  }
}

@mixin fluid-padding(
  $min-vw,
  $max-vw,
  $min-top,
  $max-top,
  $min-right,
  $max-right,
  $min-bottom,
  $max-bottom,
  $min-left,
  $max-left
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);

  $u3: unit($min-top);
  $u4: unit($max-top);

  $u5: unit($min-right);
  $u6: unit($max-right);

  $u7: unit($min-bottom);
  $u8: unit($max-bottom);

  $u9: unit($min-left);
  $u10: unit($max-left);

  @if $u1 ==
    $u2 and
    $u1 ==
    $u3 and
    $u1 ==
    $u4 and
    $u1 ==
    $u5 and
    $u1 ==
    $u6 and
    $u1 ==
    $u7 and
    $u1 ==
    $u8 and
    $u1 ==
    $u9 and
    $u1 ==
    $u10
  {
    & {
      padding: $min-top $min-right $min-bottom $min-left;
      @media screen and (min-width: $min-vw) {
        padding: calc(
            #{$min-top} + #{strip-unit($max-top - $min-top)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-right} + #{strip-unit($max-right - $min-right)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-left} + #{strip-unit($max-left - $min-left)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        padding: $max-top $max-right $max-bottom $max-left;
      }
    }
  }
}

@mixin fluid-margin(
  $min-vw,
  $max-vw,
  $min-top,
  $max-top,
  $min-right,
  $max-right,
  $min-bottom,
  $max-bottom,
  $min-left,
  $max-left
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);

  $u3: unit($min-top);
  $u4: unit($max-top);

  $u5: unit($min-right);
  $u6: unit($max-right);

  $u7: unit($min-bottom);
  $u8: unit($max-bottom);

  $u9: unit($min-left);
  $u10: unit($max-left);

  @if $u1 ==
    $u2 and
    $u1 ==
    $u3 and
    $u1 ==
    $u4 and
    $u1 ==
    $u5 and
    $u1 ==
    $u6 and
    $u1 ==
    $u7 and
    $u1 ==
    $u8 and
    $u1 ==
    $u9 and
    $u1 ==
    $u10
  {
    & {
      margin: $min-top $min-right $min-bottom $min-left;
      @media screen and (min-width: $min-vw) {
        margin: calc(
            #{$min-top} + #{strip-unit($max-top - $min-top)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-right} + #{strip-unit($max-right - $min-right)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-bottom} + #{strip-unit($max-bottom - $min-bottom)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-left} + #{strip-unit($max-left - $min-left)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        margin: $max-top $max-right $max-bottom $max-left;
      }
    }
  }
}

@mixin fluid-padding-double(
  $min-vw,
  $max-vw,
  $min-font-size,
  $max-font-size,
  $min-font-size-two,
  $max-font-size-two
) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  $u5: unit($min-font-size-two);
  $u6: unit($max-font-size-two);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 and $u1 == $u5 and $u1 == $u6 {
    & {
      padding: $min-font-size $min-font-size-two;
      @media screen and (min-width: $min-vw) {
        padding: calc(
            #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
              ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          )
          calc(
            #{$min-font-size-two} + #{strip-unit(
                $max-font-size-two - $min-font-size-two
              )} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
          );
      }

      @media screen and (min-width: $max-vw) {
        padding: $max-font-size $max-font-size-two;
      }
    }
  }
}


$min_width: 320px;
$max_width: 1660px;

$min_font_16: 10.5px;
$max_font_16: 12px;

$min_font_18: 11.5px;
$max_font_18: 12px;

$min_font_19: 12.5px;
$max_font_19: 14px;

$min_font_20: 12px;
$max_font_20: 17px;

$min_font_23: 12.5px;
$max_font_23: 18px;

$min_font_24: 14px;
$max_font_24: 20px;

$min_font_22: 13px;
$max_font_22: 19px;

$min_font_45: 26px;
$max_font_45: 36px;

$min_padding_20: 8px;
$max_padding_20: 11px;

$min_padding_14: 4px;
$max_padding_14: 8px;