@import "../../scss/config";

main {
  max-height: 100vh;
  .logo {
    @include fluid-width($min_width, $max_width, 160px, 200px);
    @include fluid-height($min_width, $max_width, 28px, 33px);
  }
  .cloud_image {
    @include fluid-width($min_width, $max_width, 300px, 525px);
    @include fluid-height($min_width, $max_width, 300px, 500px);
  }
}
