@import "../../scss/config";
.blue_button {
  background-color: $dark-blue;
  
  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }
  @include fluid-padding(
    $min_width,
    $max_width,
    10px,
    10px,
    20px,
    20px,
    10px,
    10px,
    20px,
    20px
  );

  @include fluid-radius(
    $min_width,
    $max_width,
    25px,
    30px,
    25px,
    30px,
    25px,
    30px,
    25px,
    30px
  );
}
