@import "config";
//fonts
@font-face {
  font-family: "NoirPro-Bold";
  src: local("NoirPro-Bold"),
    url(../assets/fonts/NoirPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "NoirPro-Regular";
  src: local("NoirPro-Regular"),
    url(../assets/fonts/NoirPro-Regular.ttf) format("truetype");
}
//presets
* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;

  margin: 0;
}
html,
#root,
body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  background-color: $primary-white;
  overflow: hidden;
}
html {
  overflow: auto;
  background-color: $primary-white;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
}
body {
  background-color: $primary-white;
  margin: 0;
  padding: 0;
}
main {
  height: 100%;
  max-width: 1660px;
  margin: 20px auto 0;
  padding: 0 7%;
}
::-webkit-scrollbar {
  width: 0px !important;
}
button {
  background: none;
  border: none;
}
button:hover,
button:active,
button:focus {
  outline: none;
  border: none;
}
a{
  text-decoration: none;
}

//flex

.flex_column_center {
  @include d_flex(column, center, center);
}
.flex_column_start {
  @include d_flex(column, center, flex-start);
}
.flex_row_top {
  @include d_flex(row, flex-start, space-between);
}

//spacing

.mt_60 {
  @include fluid-margin(
    $min_width,
    $max_width,
    10px,
    150px,
    0px,
    0px,
    0px,
    0px,
    0px,
    0px
  );
}
.mb_45 {
  @include fluid-margin(
    $min_width,
    $max_width,
    0px,
    0px,
    0px,
    0px,
    25px,
    55px,
    0px,
    0px
  );
}

.mb_25 {
  @include fluid-margin(
    $min_width,
    $max_width,
    0px,
    0px,
    0px,
    0px,
    15px,
    25px,
    0px,
    0px
  );
}

.m_auto{
  margin: auto;
}
//font size and color

.text_45 {
  @include fluid-font($min_width, $max_width, $min_font_45, $max_font_45);
}
.text_22{

  @include fluid-font($min_width, $max_width, $min_font_22, $max_font_22);

}
.blue {
  color: $light-blue;
}
.white {
  color: $primary-white;
}
.noirpro_bold {
  font-family: NoirPro-Bold;
}
.noirpro_reg {
  font-family: NoirPro-Regular;
}
//width
.full_width {
  width: 100%;
}
.second_width_desktop {
  width: 40%;
}
//misc
.cursor_pointer {
  cursor: pointer;
}
.ease {
  transition: all 0.2s ease;
}
//Media Queries
@media screen and(max-width:767px) {
  html,
#root,
body {
 
  overflow: auto;
}
  .second_width_desktop {
    width: 100%;
  }
  .tab_column {
    @include d_flex(column, center, space-between);
  }
  .text_center_md {
    text-align: center;
  }
  .mt_md_0{
    margin-top: 0 !important;
  }
}
